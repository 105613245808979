<template>
  <div class="page-box">
    <div class="nav-fixed-box">
      <Nav class="nav-fixed" :navFixed="navFixed"  @click.native="scrollView($event)"></Nav>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <div class="view-box">
        <p style="font-size:27pt; line-height:175%; margin:0pt 0pt 10pt; text-align:center"><a
            name="_055-1599121612466"></a><span
            style="color:#333333; font-size:27pt; font-weight:bold">应用功耗标准</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_240-1599121613409"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_063-1599121613414"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_497-1599121658980"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="1"><a name="_939-1601185948002"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">一、</span><span
            style="color:#409eff; font-size:17pt; font-weight:bold">&#xa0;</span><span
            style="color:#409eff; font-size:17pt; font-weight:bold">背景</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_459-1601185948003"></a><span
            style="font-size:12pt">此功耗标准的目的是提升安卓应用体验，营造良好的安卓应用使用环境，推动安卓应用软件运行在手机上的健康发展，通过规范化安卓应用软件功耗指标、设备资源使用、应用本身行为，提升用户使用设备的时长体验及温升体验。</span>
        </p>
        <p style="font-size:11pt; line-height:175%;"><a name="_175-1601185948003"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="2"><a name="_170-1601185948004"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">二、</span><span
            style="color:#409eff; font-size:17pt; font-weight:bold">&#xa0;</span><span
            style="color:#409eff; font-size:17pt; font-weight:bold">适用范围</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_183-1601185948005"></a><span
            style="font-size:12pt">本标准适用于Android平台各类应用的功耗衡量与判断。应用类型包含但不限于视频音乐、通讯社交、摄影美图、新闻阅读、购物优惠、生活服务、实用工具、教育学习、系统工具、金融理财、旅游出行、娱乐消遣、育儿母婴、健康美容、效率办公等。</span>
        </p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_470-1601185948005"></a><span
            style="font-size:12pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="3"><a name="_285-1601185948006"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">三、</span><span
            style="color:#409eff; font-size:17pt; font-weight:bold">&#xa0;</span><span
            style="color:#409eff; font-size:17pt; font-weight:bold">术语、定义、缩略语</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="3-1"><a name="_479-1601185948007"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">3.1.</span><span
            style="color:#333333; font-size:14pt; font-weight:bold">&#xa0;</span><span
            style="color:#333333; font-size:14pt; font-weight:bold">术语和定义</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-1"><a name="_494-1601185948007"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.1.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">应用功耗</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 25px;"><a name="_724-1601185948008"></a><span
            style="font-size:12pt">应用运行时所消耗的电量，包括应用在处理上运算所消耗的电量，应用所使用的外围设备的功耗，以及应用原因引起其它软件耗电的权重附加。</span>
        </p>
        <p style="font-size:11pt; line-height:175%;"><a name="_170-1601185948008"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-2"><a name="_545-1601185948009"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.2.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">前后台</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 25px;"><a name="_033-1601185948009"></a><span
            style="font-size:12pt">用来表示应用是否处于手机屏幕的顶层。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 41pt"><a name="_924-1601185948010"></a><span
            style="font-size:12pt">1）前台表示应用处于手机屏幕的顶层，用户可以直接看到并进行操作。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 41pt"><a name="_085-1601185948010"></a><span
            style="font-size:12pt">2）后台表示应用未处于手机屏幕的顶层，用户无法直接看到，也无法直接操作。</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_052-1601185948010"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-3"><a name="_854-1601185948011"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.3.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">休眠</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 25px;"><a name="_143-1601185948013"></a><span
            style="font-size:12pt">Android系统的一种省电方式。当系统进入休眠状态时，根据系统设置，应用处理器和其它不在使用的设备将停止运行，只剩下必要的设备（如维持通讯的基带处理器）运行，用来降低系统的耗电。</span>
        </p>
        <p style="font-size:11pt; line-height:175%;"><a name="_529-1601185948014"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:0pt 0pt 10pt 21pt;margin-top: 10pt;" class="nav-title" id="3-1-4"><a name="_250-1601185948014"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">3.1.4.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">Wakelock</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 25px;"><a name="_860-1601185948015"></a><span
            style="font-size:12pt">应用阻止系统进入休眠的一种方式，根据不同的设置表现有所不同，常见的表现为即使锁定屏幕一段时间，CPU也会一直保持运行，如音乐播放器应用在播放音乐时将屏幕关闭并保持音乐的播放。</span>
        </p>
        <p style="font-size:11pt; line-height:175%;"><a name="_214-1601185948015"></a><span
            style="font-size:11pt">&#xa0;</span><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="3-2"><a name="_163-1601185948018"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">3.2.</span><span
            style="color:#333333; font-size:14pt; font-weight:bold">&#xa0;</span><span
            style="color:#333333; font-size:14pt; font-weight:bold">缩略语</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_045-1601185948020"></a><span
            style="font-size:12pt; font-weight:bold">同等条件</span><span
            style="font-size:12pt">：相同的设备，无其他后台，相同的网络环境，相同的亮度等条件。</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_939-1601185948020"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_570-1601185948022"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:17pt; line-height:175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="4"><a name="_246-1601185948023"></a><span
            style="color:#409eff; font-size:17pt; font-weight:bold">四、</span><span
            style="color:#409eff; font-size:17pt; font-weight:bold">&#xa0;</span><span
            style="color:#409eff; font-size:17pt; font-weight:bold">功耗标准</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_085-1601185948024"></a><span
            style="font-size:12pt">通常情况下，单个应用在手机中运行的功耗可以通过电流消耗来进行评估，但电流受环境影响较大，而当前测试环境又难于模拟全部环境，导致无法测试所有情况下的电流，因此，本文除了在电流消耗上做一个基础的规范外，还将对应用在使用设备资源上做细节性规范、对应用本身的行为方式做补充规范。应用在更新版本的过程及之后，依然需要遵守本文所有规范，并且功耗基础指标、设备资源分类使用都不得变差。应用功耗可通过PowerMonitor等设备进行测试。</span>
        </p>
        <p style="font-size:11pt; line-height:175%;"><a name="_276-1601185948024"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 20pt;" class="nav-title" id="4-1"><a name="_685-1601185948025"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">4.1.</span><span
            style="color:#333333; font-size:14pt; font-weight:bold">&#xa0;</span><span
            style="color:#333333; font-size:14pt; font-weight:bold">基础要求</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_015-1601185948026"></a><span
            style="font-size:12pt; font-weight:bold">同等条件</span><span
            style="font-size:12pt">下，应用的前后台功耗指标不能超过其行业同类应用平均水平的5%。功耗指标包含但不限于以下几个方面：</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_323-1601185948026"></a><span
            style="font-size:12pt">1）静置状态下的显示基底电流大小</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_996-1601185948027"></a><span
            style="font-size:12pt">2）运行状态下的基底电流大小</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_877-1601185948027"></a><span
            style="font-size:12pt">3）维护网络连接情况下的待机电流大小</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_099-1601185948028"></a><span
            style="font-size:12pt">备注：此要求在该版标准不做强制，下版迭代时先定义好行业标准，再做强制要求</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_712-1601185948028"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin-bottom:10pt;margin-top: 10pt;" class="nav-title" id="4-2"><a name="_476-1601185948029"></a><span
            style="color:#333333; font-size:14pt; font-weight:bold">4.2.</span><span
            style="color:#333333; font-size:14pt; font-weight:bold">&#xa0;</span><span
            style="color:#333333; font-size:14pt; font-weight:bold">资源使用</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_838-1601185948029"></a><span
            style="font-size:12pt">设备资源使用上的规范，将按照以下几种设备资源分类，做更细节性的规范：</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_186-1601185948030"></a><span
            style="font-size:12pt">1）处理器：应用处理器、图像处理器、wakelock。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_858-1601185948030"></a><span
            style="font-size:12pt">2）ALARM：设置会唤醒系统的时钟。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_664-1601185948030"></a><span
            style="font-size:12pt">3）屏幕：显示屏幕。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_987-1601185948031"></a><span
            style="font-size:12pt">4）无线网络：Wi-Fi、数据连接、蓝牙、红外、NFC等。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_125-1601185948031"></a><span
            style="font-size:12pt">5）音频设备及资源：扬声器、麦克风、音视频编解码器。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_710-1601185948031"></a><span
            style="font-size:12pt">6）摄像头：前、后摄像头。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_048-1601185948032"></a><span
            style="font-size:12pt">7）位置传感器：GPS、北斗、格洛纳斯、气压传感器、AGSP网络辅助定位等。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt 21pt"><a name="_020-1601185948032"></a><span
            style="font-size:12pt">8）动作传感器：加速度传感器、磁场传感器、方向传感器、陀螺仪传感器等。</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_538-1601186370538"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:20pt 0pt 15pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-1"><a name="_060-1601185948034"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">4.2.1.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">处理器</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_499-1601186422492"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_131-1601185948034"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 处理器后台使用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估应用后台运行时cpu及wakelock的使用标准</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">应用在后台运行（用户不可见状态）</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">持续观察应用所属进程的处理器占用情况，需满足以下指标：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">a、不得占用处理器以及设置Wakelock。</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">b、除外场景结束后立即释放所有的占用。</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">c、禁止在用户无操作的情况下默认开启前台服务</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">d、禁止恶意不结束JobScheduler任务</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">以下场景除外：</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">1、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">用户主动下载</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">2、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">用户主动选择的系统更新</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">3、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">用户主动选择的数据传输</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">4、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">用户开启的导航、记步</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">5、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">用户主动开启的轨迹跟踪</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">6、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">音频播放（录音、音乐、直播等）</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">7、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">用户主动选择的安装</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">8、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">用户在状态栏有行为通知</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">9、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">即时通信的接收消息</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">用户主动开启的后台欺诈拦截、网络安全扫描、流量监控、定时提醒时</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_064-1601185948085"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_270-1601185948085"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 处理器后台占用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估处理器后台占用标准</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">满足处理器后台占用要求，并且在后台占用处理器运行</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">持续观察应用所属进程的处理器占用百分比：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">a、占用期间，选取任意3分钟，平均不得高于10%</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">b、占用期间，任意选择1个小时，平均不得高于2%</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_748-1601185948128"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_322-1601185948129"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 前台处理器占用</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">前台处理器占用</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">应用在前台运行</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">z判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">持续观察应用所属进程的处理器占用情况：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">a、在相同或者类似业务运行时，处理器占用百分比不高于其行业同类应用平均水平的5%</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">b、除非用户主动选择，不得运行与前台无关及用户感知不到的业务，如非用户选择的下载、更新、监控等</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">c、使用视频硬编码和硬解码，禁止使用软编码和软解码</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">以下场景除外：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1. 应用有明显标识（如悬浮球式、通知，并且用户可自己取消），则允许应用做用户体验相关的监控</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_013-1601185948170"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_640-1601186388944"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> Wakelock持有标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">Wakelock持有(灭屏)</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">应用在前、后台运行</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">Wakelock持有情况，需满足以下指标：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">a、非导航、音乐、视频时，在前、后台，60分钟内持有Wakelock累计不超过5分钟</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">b、除用户主动行为，比如音乐、下载、导航等外，在后台持有唤醒锁的时间不超过1秒</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt; text-decoration:line-through">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_364-1601185948214"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:20pt 0pt 15pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-2"><a name="_030-1601185948215"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">4.2.2.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">ALARM</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_687-1601186428680"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_247-1601185948215"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 后台Alarm使用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估后台Alarm的使用情况</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">应用在后台运行</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">a、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">灭屏后，禁止使用准确、可唤醒alarm</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">b、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">除心跳外，禁止使用循环Alarm</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">c、alarm间隔建议大于5分钟</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">以下场景除外：</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">1、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">播放视频、音乐时</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_796-1601185948263"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:20pt 0pt 15pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-3"><a name="_020-1601185948264"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">4.2.3.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">屏幕</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_025-1601186434125"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_419-1601185948264"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 前台屏幕使用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估前台屏幕的使用情况</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">应用在前台运行（用户可见）</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">前台时，空闲状态禁止保持屏幕常亮，如下场景：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">a、视频内容播放完毕且没选择下一集播放内容</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">b、导航或地图查看已经完成本次任务</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">c、输入法语音识别超过2分钟没有输入</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_882-1601185948306"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_618-1601185948307"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 后台屏幕使用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估后台屏幕的使用情况</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">应用在后台运行（用户不可见）</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">1、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">不允许持有屏幕锁</span></p>
              <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><span
                  style="font-size:11pt">2、</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">非用户发起操作的业务不能点亮屏幕</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">以下场景除外：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1. 后台接受消息、用户设定的定时提醒</span></p>
            </td>
          </tr>
        </table>
        <p style="margin:0pt 0pt 10pt 21pt"><a name="_014-1601185948351"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:20pt 0pt 15pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-4"><a name="_069-1601186408937"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">4.2.4.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">无线网络</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_260-1601186436891"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_770-1601185948352"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 无线网络使用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估无线网络（WIFI、移动网络、蓝牙、红外、NFC等无线信号网络）的使用情况</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">应用在后台运行（用户不可见）</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1、平均每小时使用无线网络传输数据时间不超过1分钟</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">2、平均每小时对无线网络的扫描不超过20次</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">3、如无相关业务需求，禁止扫描WIFI/BT</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">4、每个小时内，应用主动连接网络次数不得超过20次</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">5、待机时，应用通过长链接唤醒系统的间隔不得短于10分钟</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">6、网络连接断开时，禁止业务反复尝试，应等待连接事件</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">7、如确属必要的同步、下载、更新要放在WIFI(功耗较低)连接和充电的情况下执行</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">以下场景除外：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1、即时消息等必须业务</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">2、后台播放在线视频、音乐或观看直播时</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">3、后台导航时</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">4、后台下载视频、音乐或离线地图时</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">5、后台进行收发消息时</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">6、后台进行欺诈拦截、网络安全扫描或流量监控时</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">7、应用在状态栏显示下载进度</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_062-1601185948398"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:20pt 0pt 15pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-5"><a name="_577-1601185948399"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">4.2.5.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">音频设备及资源</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_513-1601185948399"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_871-1601185948399"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 后台音频设备使用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估后台音频设备的使用情况</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">应用在后台运行（用户不可见）</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1、禁止使用audio锁或持有系统音频资源</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">2、禁止以任何形式利用用户无法感知音频资源进行保活</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">播放时，以下场景除外：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1. 后台播放视频、音乐或观看直播时</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">2. 后台导航时（需有标识）</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">3. 后台定时提醒时</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">4. 应用在状态栏显示声音播放状态</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">5.</span><span
                  style="font-size:11pt">&#xa0;</span><span
                  style="font-size:11pt">后台重要语音提醒（如收钱播报）</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">录音时，以下场景除外：</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1. 后台直播时（需显示状态）</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">2. 后台智能语音识别时（需有明显标识）</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">3. 后台语音通话时</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">4. 应用在状态栏显示录音状态</span></p>
            </td>
          </tr>
        </table>
        <p style="margin:0pt 0pt 10pt 21pt"><a name="_939-1601185948448"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:20pt 0pt 15pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-6"><a name="_092-1601186461552"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">4.2.6.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">摄像头</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_990-1601186464579"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_487-1601185948448"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 摄像头使用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估摄像头资源（前置摄像头、后置摄像头、红外摄像头及闪光灯）的使用情况</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">无</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1、没有预览图像的情况下，禁止使用摄像头资源</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">2、非Camera类的应用，除非用户主动操作，禁止打开闪光灯或手电筒</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">以下场景除外：</span><span
                  style="font-size:11pt">&#xa0;</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1、人脸识别（必须有明显标识）</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_593-1601185948489"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:20pt 0pt 15pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-7"><a name="_093-1601185948490"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">4.2.7.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">定位传感器</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_323-1601186468764"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_030-1601185948491"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 定位传感器使用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估定位传感器（GPS、AGPS、北斗、格洛纳斯、气压传感器等）的使用情况</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">无</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1、非导航类、轨迹跟踪类、地图类、运动类应用，禁止持续使用定位传感器。每次时长不能超过1.5分钟，累计不能超过5分钟/小时</span>
              </p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">2、非后台导航、轨迹追踪时，禁止在后台使用位置传感器</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_231-1601185948535"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="margin:20pt 0pt 15pt 21pt;margin-top: 10pt;" class="nav-title" id="4-2-8"><a name="_133-1601185948535"></a><span
            style="color:#595959; font-size:12pt; font-weight:bold">4.2.8.</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">&#xa0;</span><span
            style="color:#595959; font-size:12pt; font-weight:bold">动作传感器</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_844-1601186479014"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;margin:10px 0 10px 25px; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_684-1601185948536"></a><span
            style="font-size:11pt; font-weight:bold"><span style="display: inline-block;border: 3px solid #595959;border-radius: 50%;transform: translateY(-2px);"></span> 动作传感器使用标准</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin:20px 20pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">评估动作传感器（含加速度传感器、磁场传感器、方向传感器、陀螺仪传感器等）的使用情况</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">无</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">1、运动健康类应用（如计步），请使用谷歌标准接口获取结果。不要单独使用sensor重新计算，并且要尽量降低取样频率</span>
              </p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">2、平均每次使用动作传感器不应超过30秒</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">3、应用在后台，非导航、记步场景禁止使用动作传感器</span></p>
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">应用有明显标识，并且用户可选择取消的情况下，可允许应用使用sensor计步获取更精确的结果</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%;"><a name="_179-1601185948581"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%;"><a name="_788-1601185948581"></a><span
            style="font-size:11pt">&#xa0;</span></p>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Nav from "@/components/documentsBase/Nav";
export default {
  components: { Nav },
  metaInfo: {
    title:'ITGSA | 移动智能终端生态联盟 | 应用功耗标准',
    meta: [
      {
        name: 'keywords',
        content: 'ITGSA,生态联盟,功耗,金标认证'
      },
      {
        name: 'description',
        content: '此功耗标准的目的是提升安卓应用体验，营造良好的安卓应用使用环境，推动安卓应用软件运行在手机上的健康发展，通过规范化安卓应用软件功耗指标、设备资源使用、应用本身行为，提升用户使用设备的时长体验及温升体验。'
      },
    ]
  },
  created() {},
  data() {
    return {
      navFixed: [
          {
            name: "一、 背景",
            isActive:null,
            class:'#1',
          },
          {
            name: "二、 适用范围",
            isActive:null,
            class:'#2',
          },
          {
            name: "三、 术语、定义和缩略语",
            isActive:null,
            class:'#3',
            child:[
              {
                name: "3.1 术语和定义",
                isActive:null,
                class:'#3-1',
                child:[
                  {
                    name: "3.1.1 应用功耗",
                    isActive:null,
                    class:'#3-1-1',
                  },
                  {
                    name: "3.1.2 前后台",
                    isActive:null,
                    class:'#3-1-2',
                  },
                  {
                    name: "3.1.3 休眠",
                    isActive:null,
                    class:'#3-1-3',
                  },
                  {
                    name: "3.1.4 Wakelock",
                    isActive:null,
                    class:'#3-1-4',
                  },
                ],
              },
              {
                name: "3.2 缩略语",
                isActive:null,
                class:'#3-2',
              },
            ],
          },
          {
            name: "四、 功耗标准",
            isActive:null,
            class:'#4',
            child:[
              {
                name: "4.1 基础要求",
                isActive:null,
                class:'#4-1',
              },
              {
                name: "4.2 资源使用",
                isActive:null,
                class:'#4-2',
                child:[
                  {
                    name: "4.2.1 处理器",
                    isActive:null,
                    class:'#4-2-1',
                  },
                  {
                    name: "4.2.2 ALARM",
                    isActive:null,
                    class:'#4-2-2',
                  },
                  {
                    name: "4.2.3 屏幕",
                    isActive:null,
                    class:'#4-2-3',
                  },
                  {
                    name: "4.2.4 无线网络",
                    isActive:null,
                    class:'#4-2-4',
                  },
                  {
                    name: "4.2.5 音频设备及资源",
                    isActive:null,
                    class:'#4-2-5',
                  },
                  {
                    name: "4.2.6 摄像头",
                    isActive:null,
                    class:'#4-2-6',
                  },
                  {
                    name: "4.2.7 定位传感器",
                    isActive:null,
                    class:'#4-2-7',
                  },
                  {
                    name: "4.2.8 动作传感器",
                    isActive:null,
                    class:'#4-2-8',
                  },
                ],
              },
            ],
          },
        ],
    };
  },
  mounted() {
    $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
    this.scrollContainer = this.$refs['view-box'].wrap;  // 将 el-scrollbar 的 wrap 对象找出来，指定给 scroll-container 
    this.scrollContainer.addEventListener('scroll',utils.throttle(this.handleScroll,100,200));
  },
  methods: {
    scrollView(event){
      if($(event.target).attr("view")){
        this.$refs['view-box'].wrap.scrollTop = $($(event.target).attr("view")).position().top ;
      }
    },
    //滚动检查
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = this.scrollContainer.scrollTop;
      if(scrollTop){
        for(let i=($(".nav-title").length-1);i>=0;i--){
          if (scrollTop > $(".nav-title").eq(i).position().top-30) { 
            $('.nav-fixed-box .name').css("color","#000");
            $('.nav-fixed-box .name').eq(i).css("color","#1989fa");
            this.awaitScroll($('.nav-fixed-box .name').eq(i).position().top);
            break;
          }
        }
      }else{
        $('.nav-fixed-box .name').css("color","#000");
        $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
        this.awaitScroll(0);
      }
    },
    //同步目录滚动
    awaitScroll(top) {
      $('.nav-fixed-box').animate({
        scrollTop:top
      },300);
    },
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<style scoped lang="scss">/*主体*/
.page-box{
  position: relative;
  display: flex;
  .nav-fixed-box{
    width:300px;
    height: 100%;
    padding: 40px 15px;
    overflow-y: scroll;
    .nav-fixed{
      position: relative;
    }
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .nav-fixed-box {
      display: none;
    }
}
</style>
